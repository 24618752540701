<template>
	<div id="fundsTransfer" class="input-heading">
		<div v-if="availableOffers.length > 0">
			<div v-if="!restrictedNeedsMoreInfo">
				<h2>{{ languageStrings.buyInOffers }}</h2>
			</div>
			<div v-else class="text-center">
				<h2>{{ languageStrings.needsMoreInfo }}</h2>
				<p>
					{{ languageStrings.buyInThreshold }}:
					{{
						systemCurrencyTool.formatCurrency(
							webPayOptions?.taxAmlRestrictions?.buyInThresholdCOC,
							systemCurrencyTool.displayType.minorWholeOrFull
						)
					}}
				</p>
				<p>
					{{ languageStrings.cashOutThreshold }}:
					{{
						systemCurrencyTool.formatCurrency(
							webPayOptions?.taxAmlRestrictions?.cashOutThresholdCOC,
							systemCurrencyTool.displayType.minorWholeOrFull
						)
					}}
				</p>
				<div class="info-button">
					<router-link class="btn" :title="languageStrings.idCheckNeeded" to="/identificationStatus">{{
						languageStrings.idCheckNeeded
					}}</router-link>
					<router-link class="btn" :title="languageStrings.morePersonalInfoNeeded" to="/userProfile">{{
						languageStrings.morePersonalInfoNeeded
					}}</router-link>
				</div>
			</div>
			<div v-if="availableOffers.length > 0":class="isMobile ? 'mobile-grid':  ''">
			<div class="buy-in-offer card-container" :class="isMobile ? 'is-mobile' : ''">
				<BuyInOffer
					class="card"
					v-for="(buyInOffer, index) in availableOffers"
					:id="`offer-${buyInOffer.id}`"
					:key="index"
					:buyInOffer="buyInOffer"
					:playerState="playerState"
					:inGameCurrencyTool="inGameCurrencyTool"
					:systemCurrencyTool="systemCurrencyTool"
					:languageStrings="languageStrings"
					:languageErrorStrings="languageErrorStrings"
					:systemSettings="systemSettings"
					:webPayOptions="webPayOptions"
					:inGameCurrencyInfo="inGameCurrencyInfo"
					:isMobile="isMobile"
					:restrictedNeedsMoreInfo="restrictedNeedsMoreInfo"
				/>
			</div>
			</div>
		</div>
		<div v-else>
			<h2>{{ languageStrings.noBuyInOffersAvailable }}</h2>
		</div>
	</div>
</template>

<script>
import BuyInOffer from "@/components/BuyInOffer.vue";
import { onBeforeUnmount } from "vue";

export default {
	name: "BuyIns",
	inheritAttrs: false,
	props: {
		playerState: {
			type: Object,
		},
		appDataBus: {
			type: Object,
		},
		isMobile: Boolean,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
		availableOffers: Array,
		systemSettings: Object,
		webPayOptions: Object,
		inGameCurrencyInfo: Object,
		casinoList: Array,
	},
	components: {
		BuyInOffer,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			buyInOffers: this.availableOffers.sort((a, b) => b.priority - a.priority),
			currencyInfo: this.playerState.currencyInfo,
			idCheckNeeded: this.webPayOptions?.taxAmlRestrictions?.idCheckNeeded,
			morePersonalInfoNeeded: this.webPayOptions?.taxAmlRestrictions?.morePersonalInfoNeeded,
			restrictedNeedsMoreInfo:
				this.webPayOptions?.taxAmlRestrictions?.idCheckNeeded ||
				this.webPayOptions?.taxAmlRestrictions?.morePersonalInfoNeeded,
		};
	},
	created() {
		this.eventBus.on("casinoSelected", (selected) => {});
		onBeforeUnmount(() => {
			this.eventBus.off("casinoSelected");
		});
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.buy-in-offer {
	display: flex;
	flex-flow: row wrap;
}

.input-section div {
	flex-direction: column;
}

#fundsTransfer {
	padding: 15px 15px 5em;
	margin-bottom: 30%;
	/* padding: 15px; */
	height: calc(100vh - 15em);
	overflow: hidden auto;
}

.fundsTransfer {
	width: 100%;
	margin: 30px auto;
}

.fundsTransfer tr {
	cursor: pointer;
}

/* #fundsTransfer::-webkit-scrollbar-track {
	box-shadow: inset 1px 1px 6px rgb(0 0 0 / 75%);
	border-radius: 16px;
	background-color: #434250;
}

#fundsTransfer::-webkit-scrollbar {
	width: 32px;
}

#fundsTransfer::-webkit-scrollbar-thumb {
	border-radius: 16px;
	box-shadow: inset -2px -2px 6px rgb(0 0 0 / 75%);
	background-color: #bccfe5;
} */

#fundsTransfer .input-section {
	background-color: #24272c;
	padding: 30px;
	border-radius: 8px;
	/* box-shadow: 2px 3px 8px rgb(0 0 0 / 75%), inset 4px 3px 10px 4px rgb(42 63 88 / 30%),
		inset -2px -2px 10px 2px rgb(0 0 0 / 30%); */
}

.card-container {
	display: block;
	/* padding: 15px; */
}

.card {
	position: relative;
	/* margin: 15px auto; */
	padding: 15px;
	background-color: #bccfe5;
	color: #000;
	font-weight: bold;
	border-radius: 0.5em;
	/* box-shadow: 2px 3px 5px 0px hsl(0deg 0% 0% / 70%); */
	user-select: none;
	min-width: calc(100% - 30px);
	background-image: url("../../public/styleOverrides/encore_online_pixel_bg.png");
	background-size: cover;
}

.transfer,
.reprint {
	margin: 5px auto;
}

.card-container.is-mobile {
	margin-bottom: 15px;
	padding-bottom: 100px;
}

@media (min-width: 768px) {
	.card-container {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(31%, 1fr));
		column-gap: 2%;
	}

	.card-container.two-col {
		grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
	}

	.card-container.three-col {
		grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
	}

	.two-col .card {
		width: 95%;
	}
}

.info-button {
	display: flex;
	flex-flow: column;
	margin: auto;
	align-items: center;
}

.info-button .btn {
	margin-bottom: 15px;
	text-decoration: none;
}

.text-center {
	text-align: center;
}

@media (min-width: 1024px) {
	.card-container {
		grid-template-columns: repeat(auto-fill, minmax(32%, 1fr));
	}
}

.encore-background {
	background-image: url("../../public/styleOverrides/encore_online_pixel_bg.png");
	background-size: cover;
}

.mobile-grid {
	display: grid; 
	justify-content: center;
}
</style>
